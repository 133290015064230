<template>
  <div class="main-styles">
    <p>
      冠军投注<br />在指定的冠军盘中，预测一场锦标赛, 联赛,
      或者比赛的冠军。所选择的队伍或选手获得最终胜利视为符合派彩条件，包括：
    </p>
    <p>1、联赛的最终结果，例如: 世界杯冠军或F1车手冠军。</p>
    <p>2、在预赛中胜出，例如: 世界杯小组冠军。</p>
    <p>3、在最后一场赛事中，例如: 能否出线，能否踢加时，能否踢点球。</p>
    <p>4、比赛的最终结果，例如：F1赛事的个人冠军。</p>
    <p>5、最高得分数。</p>
    <p>6、最优秀的选手等。</p>
    <p><br />一般规则<br />1、所有冠军投注基于比赛的最终结果。</p>
    <p>2、无论挑选的人或球队是否有参与，所有冠军投注都视为有效。</p>
    <p>3、冠军是根据符合最后判定结果为赢的球队/球员。</p>
    <p>
      4、无论在什么情况下，如果使用"其他球员"或"其他球队"代替名称的参赛者将被视为无名称。
    </p>
    <p>5、如果体育项目有开出特殊规则，则其将取代一般适用规则。</p>
    <p>
      6、冠军项目月份榜首榜尾结算规则:
      此项目计算方式于该月第一日至最后一日（英国时间23:
      59），如遇最后一日赛事延赛或赛事取消则不予计算，不影响该项目的计算。延迟或取消的赛事不予计算，如果由于人为错误或技术故障，赛果出来后盘口没有关闭，公司有权取消因此错误下注的注单。
    </p>
    <p>
      7、冠军项目月份榜首榜尾结算规则:
      此项目计算方式于该月第一日至最后一日（英国时间23:
      59），如遇最后一日赛事延赛或赛事取消则不予计算，不影响该项目的计算。延迟或取消的赛事不予计算，如果由于人为错误或技术故障，赛果出来后盘口没有关闭，公司有权取消因此错误下注的注单。
    </p>
  </div>
</template>
